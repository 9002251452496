<template>
    <v-container>
      <v-row class="elevation-1">
        <v-col cols="6" lg="12">
          <v-btn
            id="create-new-fiscal-year"
            class="mx-2" color="primary"
            @click="openDialogbox"
          >
            Create New Fiscal Year
          </v-btn>
          <CreateFiscalYear
            :dialog="dialog"
            @dialogVal="setDialog"
            :programDetails="getProgramDetails"
          />
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import CreateFiscalYear from '../../../components/War/Funds/CreateFiscalYear.vue';
import { getProgramDetailsByParams } from '../../../components/War/Transaction/warTransaction.util';

export default {
  name: 'WarrantsAppropriationsCreateFiscalYear',
  components: {
    CreateFiscalYear,
  },
  data: () => ({
    dialog: false,
  }),
  created() {},
  computed: {
    getProgramDetails() {
      return [...getProgramDetailsByParams(this)];
    },
  },
  methods: {
    openDialogbox() {
      this.dialog = true;
    },
    setDialog(val) {
      this.dialog = val;
    },
  },
};
</script>
