<template>
<div>
  <v-container>
    <HandleCreateNewFYAdditionalDataGrp
      :dialog="showAdditionalDataGrpDialog"
      :response="aditionalDataResultObj"
      :programName="programName"
      @openCloseDialog="openCloseDialog"
    />
    <v-dialog
      v-model="isDialog"
      persistent
      max-width="60vw">

      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>

      <v-card
        v-show="createFundsDialog">
        <v-card-title>
          <span v-html="dialogHeader"></span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container class="grey lighten-5">
              <v-row
                align="center">
                <v-col cols="3">
                  <v-text-field
                    v-model.number="nextFiscalYear"
                    label="Fiscal Year"
                    type="number"
                    @keyup="fetchFunds(nextFiscalYear)"
                    @keydown="fetchFunds(nextFiscalYear)"
                    @blur="fetchFunds(nextFiscalYear)"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <MultiItemSelect
                    label="Fund"
                    item-text="fund"
                    item-value="_id"
                    :items="funds"
                    :item-list="fundList"
                    dense
                    single-line
                    @selected-items="selectedFunds"
                  ></MultiItemSelect>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-btn
            color="blue darken-1"
            text
            @click="performAction"
          >
            Create
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </v-card>

      <v-card
        v-show="flow.step1"
        class="CreateBeginningBalance">
          <v-card-title>
            Would you like to create beginning balances for your "<b>Cash Funds</b>" ?
          </v-card-title>
          <v-card-text>
            <v-btn
              color="blue darken-1"
              text
              @click="goToSetp2"
            >
              Yes
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="completeStep1"
            >
              No
            </v-btn>
            <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </v-card>

      <v-card
        v-show="flow.step2"
        class="considertotalEncumberedAmount">
          <v-card-title>
              Would you like to consider the "<b>Total Encumbered from Clerk</b>"
              values while creating Beginning Balances?
          </v-card-title>
        <v-card-text>
          <v-btn
            color="blue darken-1"
            text
            @click="goToSetp3"
          >
            Yes
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="completeStep2"
          >
            No
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </v-card>

      <v-card
        v-show="flow.step3"
        class="updateTotalEncumberedAmount">
          <v-card-title>
              Have you updated the "<b>Total Encumbered from Clerk</b>"
              values under the "<b>Shared Lists > Funds</b>" menu?
          </v-card-title>
          <v-card-text>
            <v-btn
              color="blue darken-1"
              text
              @click="completeStep3"
            >
              Yes
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="goToSetp4"
            >
              No
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              Cancel
            </v-btn>
          </v-card-text>
      </v-card>

      <v-card
        v-show="flow.step4"
        class="updateTotalEncumberedAmount">
          <v-card-title>
              Please update your Total Encumbered from Clerk field for funds needing attention.<br>
              This procedure has been cancelled and no funds or balances have been created.
          </v-card-title>
          <v-card-text>
            <v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              Close
            </v-btn>
          </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapState,
} from 'vuex';
import MultiItemSelect from '@/components/common/base/MultiItemSelect.vue';
import HandleCreateNewFYAdditionalDataGrp from '@/components/common/createNewFiscalYear/HandleCreateNewFYAdditionalDataGrp.vue';
import {
  toFiscalYear,
  toIsoDate,
} from '../../../util/shared/tmc-global';
import { isEmptyObject } from '../../../util/shared/vue-global';

const defaultFlow = () => ({
  step1: true,
  step2: false,
  step3: false,
  step4: false,
  step5: false,
  step6: false,
  step7: false,
});

export default {
  components: {
    MultiItemSelect,
    HandleCreateNewFYAdditionalDataGrp,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    programDetails: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    fundBaseCrudKey: 'sharedFund',
    currentFiscalYear: undefined,
    nextFiscalYear: undefined,
    flow: defaultFlow(),
    funds: [],
    selectedFundList: [],
    createFundsDialog: false,
    performActionFor: '',
    dialogHeader: '',
    overlay: false,
    program: '',
    showAdditionalDataGrpDialog: false,
    aditionalDataResultObj: {},
    programName: '',
  }),
  created() {
    this.program = (this.programDetails[0] || {}).baseCrudKey;
    this.loadConfig({ requiredScope: 'warconfig' })
      .then(() => this.checkFundOnly())
      .catch((e) => console.error(e));
    this.initializeDialogBox();
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'items',
      'index',
    ]),
    ...mapGetters(['todaysDate']),
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem || {},
    }),
    isDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('dialogVal', value);
      },
    },
    fundList() {
      return this.items(this.fundBaseCrudKey);
    },
    isFundOnly() {
      return this.warSettingItem && this.warSettingItem.fundsOnly;
    },
  },
  methods: {
    ...mapActions('createNewFiscalYearManager', ['createNewFiscalYear']),
    ...mapActions('shared/fund', [
      'upsertFund',
      'loadFunds',
    ]),
    ...mapActions('war/transaction', [
      'upsrtTransaction',
      'loadFYBalance',
      'loadFiscalRegYears',
    ]),
    ...mapActions('SystemConfig', [
      'loadConfig',
    ]),
    async initializeDialogBox() {
      const fyReg = await this.loadFiscalRegYears({ baseCrudKey: this.program });
      this.currentFiscalYear = (fyReg.max != null)
        ? fyReg.max : toFiscalYear(new Date(this.todaysDate));
      this.nextFiscalYear = this.currentFiscalYear + 1;
      this.fetchFunds(this.nextFiscalYear);
    },
    completeStep1() {
      this.flow.step1 = false;
      this.createFundsDialog = true;
      this.dialogHeader = 'Create Funds with <b>"Zero"</b> Balance';
      this.performActionFor = 'step1';
    },
    completeStep2() {
      this.flow.step2 = false;
      this.createFundsDialog = true;
      this.selectedFundList = [];
      this.dialogHeader = `Create funds for FY ${this.nextFiscalYear} and Create positive and negative supplements using "<b>Remaining Balance</b>"`;
      this.performActionFor = 'step2';
    },
    completeStep3() {
      this.flow.step3 = false;
      this.createFundsDialog = true;
      this.selectedFundList = [];
      this.dialogHeader = 'Create positive and negative supplements using "<b>Total Encumbered from Clerk</b>"';
      this.performActionFor = 'step3';
    },
    goToSetp2() {
      this.flow.step1 = false;
      this.flow.step2 = true;
    },
    goToSetp3() {
      this.flow.step2 = false;
      this.flow.step3 = true;
    },
    goToSetp4() {
      this.flow.step3 = false;
      this.flow.step4 = true;
    },
    close() {
      this.loadFundData();
      this.resetSteps();
      this.isDialog = false;
      this.createFundsDialog = false;
      this.dialogHeader = '';
      this.performActionFor = '';
      this.checkFundOnly();
    },
    checkFundOnly() {
      if (!this.isFundOnly) {
        this.completeStep1();
      }
    },
    resetSteps() {
      this.flow = defaultFlow();
    },
    selectedFunds(val) {
      this.funds = val;
    },
    loadFundData() {
      this.funds = this.mapCollectionVal(this.fundList, '_id');
    },
    mapCollectionVal(itemList, itemValue) {
      return [...(itemList || []).map((item) => item[`${itemValue}`])];
    },
    createSelectedFunds() {
      this.selectedFundList = (this.fundList || [])
        .filter((f) => (this.funds || []).includes(f._id));
    },
    async warCreateNewFiscalYear(createSupplementsWithRemainingBal = false, isConsiderTEA = false) {
      this.selectedFundList = (this.fundList || [])
        .filter((f) => (this.funds || []).includes(f._id));
      const result = await this.createNewFiscalYear({
        programName: this.program,
        nextFiscalYear: this.nextFiscalYear,
        currentFiscalYear: this.currentFiscalYear,
        params: {
          fundsList: this.selectedFundList,
          createSupplementsWithRemainingBal,
          isConsiderTEA,
        },
      });
      return result;
    },
    async createFunds() {
      const newFundsArr = [];
      this.createSelectedFunds();
      for (let i = 0; i < (this.selectedFundList || []).length; i += 1) {
        const list = this.selectedFundList[i];
        const newFunds = {
          fiscalYear: this.nextFiscalYear,
          type: list.type,
          description: list.description,
          bank: (list.bank && list.bank._id),
          fund: list.fund,
          crossReferenceFund: list.crossReferenceFund,
        };
        const result = await this.upsertFund({ item: newFunds });
        if (result && result.data && result.data.upsertFund) {
          newFundsArr.push(result.data.upsertFund);
        }
      }
      return newFundsArr;
    },
    async createSupplementsWithRemainingBal(newFunds, isConsiderTEA = false) {
      for (let i = 0; i < (this.selectedFundList || []).length; i += 1) {
        const fundObj = this.selectedFundList[i];
        const remaningFundBal = await this.loadFYBalance({
          fiscalYear: this.currentFiscalYear,
          fund: fundObj._id,
        });
        const newFundObj = newFunds.find((f) => f.fund === fundObj.fund);
        let { balance } = remaningFundBal.data.warFyBalance;

        if (isConsiderTEA) {
          balance -= fundObj.totalEncumberedAmount;
        }

        const regDateOfPrevFY = `${this.currentFiscalYear}-06-30`;
        this.upsertSuppliment({
          registerDate: toIsoDate(regDateOfPrevFY),
          issueDate: toIsoDate(regDateOfPrevFY),
          fiscalYear: this.currentFiscalYear,
          fund: fundObj._id,
          amount: (balance * -1),
        });

        const regDateOfNewFY = `${this.nextFiscalYear}-07-01`;
        this.upsertSuppliment({
          registerDate: toIsoDate(regDateOfNewFY),
          issueDate: toIsoDate(regDateOfNewFY),
          fiscalYear: this.nextFiscalYear,
          fund: newFundObj._id,
          amount: balance,
        });
      }
      return true;
    },
    upsertSuppliment({
      registerDate, issueDate, fiscalYear, fund, amount,
    }) {
      const newSupplement = {
        type: 'SUPPLEMENTS',
        registerDate,
        issueDate,
        fiscalYear,
        status: 'OUTSTANDING',
        amount,
        details: [{
          fund,
          amount,
        }],
      };
      this.upsrtTransaction({ item: newSupplement, baseCrudKey: this.program });
    },
    async handleCreateNewFiscalYearResult(resultObj) {
      if (!isEmptyObject(resultObj)) {
        if ((resultObj.componentStatusArr || []).length > 0) {
          this.showAdditionalDataGrpDialog = true;
          this.aditionalDataResultObj = resultObj;
          this.programName = resultObj.programName;
        }
      }
    },
    async performAction() {
      this.overlay = true;
      let resultObj;
      // Will remove comments once New method for Create New FY works fine.
      switch (this.performActionFor) {
        case 'step1':
          // await this.createFunds();
          resultObj = await this.warCreateNewFiscalYear();
          break;
        case 'step2':
          // newFunds = await this.createFunds();
          // await this.createSupplementsWithRemainingBal(newFunds);
          resultObj = await this.warCreateNewFiscalYear(true);
          break;
        case 'step3':
          // newFunds = await this.createFunds();
          // await this.createSupplementsWithRemainingBal(newFunds, true);
          resultObj = await this.warCreateNewFiscalYear(true, true);
          break;
        default:
          // do nothing
          break;
      }
      this.overlay = false;
      this.close();
      this.handleCreateNewFiscalYearResult(resultObj);
    },
    async fetchFunds(fiscalYear) {
      const inputFiscalYear = fiscalYear - 1;
      await this.loadFunds({
        criteria: {
          fiscalYear: inputFiscalYear,
        },
        baseCrudKey: this.fundBaseCrudKey,
      });
      this.loadFundData();
    },
    openCloseDialog(value) {
      this.showAdditionalDataGrpDialog = value;
    },
  },
};
</script>
